import React from "react"
import Layout from "../layouts";
import Container from "../components/./container"
import styled from "styled-components"
import Footer from "../components/footer";
import Seo from "../components/seo"

const Wrapper = styled.div`
padding-top: 15vh;
padding-bottom: 15vh;

section{width:100%;}

h1 {
    font-size: 30px;
    margin-bottom: 38px;
}

h2 {
    font-size: 18px!important;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 11px;
    border-bottom: 1px solid;
    letter-spacing: 0.1em!important;
    padding-bottom: 6px;
}

h3 {
    line-height: 150%;
    font-size: 29px;
    letter-spacing: 0.1em;
    width: 185px;
    font-weight: 600;
    font-size: 16px;
    width: auto;
    text-transform: uppercase;
    margin-bottom: 0.75em;
}

p, span, div, h2, h4, h5, li {
    color: #fff;
    line-height: 150%;
    font-size: 13px;
    letter-spacing: 0.05em;
}

.h1, h3 {
    margin-top: 24px;
}

h2{
   margin-top: 38px;
}
ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-inline-start: 40px;
}


.CookieDeclarationType {
    display: block;
    margin: 12px 0 12px 0;
    padding: 8px 8px 0 8px;
    border: 1px solid #fff;
    vertical-align: top;
}

.CookieDeclarationTypeHeader {
    font-weight: bold;
}

.CookieDeclarationTableHeader {
    font-weight: bold;
    border-bottom: 1px solid #fff;
    text-align: left;
    padding: 4px;
    overflow: hidden;
}

@media(max-width: 600px){
    h1 {
    margin-bottom: 0;
}

padding-top: 15vh;
padding-bottom: 0;

.CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable colgroup col:nth-child(n+5) {
    display: none;
}

p, span, div, h2, h4, h5, li {
    font-size: 12px;
}
}
`
const PrivacyPolicyPage = () => {

    return (<>
        <Layout>
            <Seo title="Privacy Policy - Vertual" />
            <main id="content">
                <Wrapper>
                    <Container reversed direction="column" position="intro">
                        <header className="page-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1>Privacy Policy</h1>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="light-color mt fp-auto-height" id="VirtualRealityCo">
                            <div className="container marginBottom">
                                <div id='ppBody'>
                                    <div className="col-2">
                                        <h2 id="types_of_data">Types of Data collected</h2>
                                        <p>
                                            Among the types of Personal Data that this website collects, by itself or through third parties, there are:
                                            Cookies, Usage Data, first name, last name and phone number.
                                        </p>
                                        <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br />
                                            The Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this website.<br />
                                            All Data requested by this website is mandatory and failure to provide this Data may make it impossible for this website to provide its services. In cases where this website specifically states that some Data is not mandatory, Users are free not to communicate this Data without any consequences on the availability or the functioning of the service.<br />
                                            Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br />Any use of Cookies – or of other tracking tools – by this website or by the owners of third-party services used by this website serves the purpose of providing the service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
                                        </p>
                                        <p>Users are responsible for any third-party Personal Data obtained, published or shared through this website and confirm that they have the third party's consent to provide the Data to the Owner.</p>

                                    </div>


                                    <div className="one_line_col">
                                        <h2 id="place_of_processing">Mode and place of processing the Data</h2>
                                        <h3>Methods of processing</h3>
                                        <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br />The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</p>

                                        <h3>Place</h3>
                                        <p>The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.</p>
                                        <h2>Retention time</h2>
                                        <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data.</p>
                                    </div>

                                    <div className="one_line_col">
                                        <h3 id="use_collected_data">The use of the collected Data</h3>
                                        <p>
                                            The Data concerning the User is collected to allow the Owner to provide its services, as well as for the following purposes:
                                            Analytics and Contacting the User.
                                        </p>
                                        <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>
                                    </div>
                                    <div className="one_line_col">
                                        <h2 id="data_processing_detailed_info">Detailed information on the processing of Personal Data</h2>
                                        <p>Personal Data is collected for the following purposes and using the following services:</p>
                                        <ul className="for_boxes">
                                            <li>
                                                <div className="box_primary box_10 expand expanded">
                                                    <h3 className="expand-click w_icon_24 policyicon_purpose_4566314">Analytics</h3>
                                                    <div className="expand-content">
                                                        <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
                                                        <h4>Google Analytics with anonymized IP (Google Inc.)</h4>
                                                        <div className="wrap">
                                                            <p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this website, to prepare reports on its activities and share them with other Google services.<br />
                                                                Google may use the Data collected to contextualize and personalize the ads of its own advertising network.<br />
                                                                This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.</p>
                                                        </div>
                                                        <p>
                                                            Personal Data collected:
                                                            Cookies and Usage Data.
                                                        </p>
                                                        <p>
                                                            Place of processing:
                                                            United States
                                                            –
                                                            <a href="https://www.google.com/intl/en/policies/privacy/" rel="noreferrer" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" rel="noreferrer" target="_blank">Opt Out</a>.
                                                        </p>
                                                        <h4>Google Analytics (Google Inc.)</h4>
                                                        <div className="wrap">
                                                            <p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this website, to prepare reports on its activities and share them with other Google services.<br />
                                                                Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
                                                        </div>
                                                        <p>
                                                            Personal Data collected:
                                                            Cookies and Usage Data.
                                                        </p>
                                                        <p>
                                                            Place of processing:
                                                            United States
                                                            –
                                                            <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target="_blank">Opt Out</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="box_primary box_10 expand expanded">
                                                    <h3 className="expand-click w_icon_24 policyicon_purpose_4566337">Contacting the User</h3>
                                                    <div className="expand-content">
                                                        <h4>Contact form (this website)</h4>
                                                        <div className="wrap">
                                                            <p>By filling in the contact form with their Data, the User authorizes this website to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p>
                                                        </div>
                                                        <p>
                                                            Personal Data collected:
                                                            first name, last name, company, email and phone number.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="one_line_col">
                                        <h2 id="further_data_processing_info">Additional information about Data collection and processing</h2>
                                        <h3>Legal action</h3>
                                        <p>
                                            The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of this website or the related services.<br />The User declares to be aware that the Data Controller may be required to reveal personal data upon request of public authorities.
                                        </p>
                                        <h3>Additional information about User's Personal Data</h3>
                                        <p>
                                            In addition to the information contained in this privacy policy, this website may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.
                                        </p>
                                        <h3>System logs and maintenance</h3>
                                        <p>
                                            For operation and maintenance purposes, this website and any third-party services may collect files that record interaction with this website (System logs) use other Personal Data (such as the IP Address) for this purpose.
                                        </p>
                                        <h3>Information not contained in this policy</h3>
                                        <p>
                                            More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the beginning of this document.
                                        </p>
                                        <h3>The rights of Users</h3>
                                        <p>
                                            Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out above.
                                        </p>
                                        <p>
                                            This website does not support “Do Not Track” requests.<br />To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
                                        </p>
                                        <h3>Changes to this privacy policy</h3>
                                        <p>
                                            The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this website and can request that the Data Controller remove the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.
                                        </p>
                                        <h3>Information about this privacy policy</h3>
                                        <p>
                                            The Data Controller is responsible for this privacy policy, prepared starting from the modules provided by iubenda and hosted on iubenda's servers.
                                        </p>
                                    </div>

                                    <h2>COOKIES</h2>
                                    <div className="CookieDeclaration" lang="en" dir="ltr">
                                        <p className="CookieDeclarationDialogText">This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>
                                        <p className="CookieDeclarationIntro">Cookies are small text files that can be used by websites to make a user's experience more efficient.<br /><br />The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.<br /><br />This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</p>
                                        <p>Your consent applies to the following domains: vertual.tv</p>

                                        <div className="CookieDeclarationType" lang="en" dir="ltr">
                                            <p className="CookieDeclarationTypeHeader">Statistics (11) </p>
                                            <p className="CookieDeclarationTypeDescription">Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</p>
                                            <table className="CookieDeclarationTable">
                                                <colgroup>        <col style={{ width: "20%" }} />        <col style={{ width: "20%" }} />        <col style={{ width: "35%" }} />        <col style={{ width: "15%" }} />        <col style={{ width: "10%" }} />      </colgroup>

                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="CookieDeclarationTableHeader" dir="ltr">Name</th>
                                                        <th scope="col" className="CookieDeclarationTableHeader" dir="ltr">Provider</th>
                                                        <th scope="col" className="CookieDeclarationTableHeader" dir="ltr">Purpose</th>
                                                        <th scope="col" className="CookieDeclarationTableHeader" dir="ltr">Expiry</th>
                                                        <th scope="col" className="CookieDeclarationTableHeader" dir="ltr">Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utm.gif</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Google Analytics Tracking Code that logs details about the visitor's browser and computer.</td>
                                                        <td className="CookieDeclarationTableCell">Session</td>
                                                        <td className="CookieDeclarationTableCell">Pixel Tracker</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utma</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Collects data on the number of times a user has visited the website as well as dates for the first and most recent visit. Used by Google Analytics.</td>
                                                        <td className="CookieDeclarationTableCell">2 years</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utmb</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Registers a timestamp with the exact time of when the user accessed the website. Used by Google Analytics to calculate the duration of a website visit.</td>
                                                        <td className="CookieDeclarationTableCell">1 day</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utmc</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Registers a timestamp with the exact time of when the user leaves the website. Used by Google Analytics to calculate the duration of a website visit.</td>
                                                        <td className="CookieDeclarationTableCell">Session</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utmt</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Used to throttle the speed of requests to the server.</td>
                                                        <td className="CookieDeclarationTableCell">1 day</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utmv</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Saves user-defined tracking parameters for use in Google Analytics.</td>
                                                        <td className="CookieDeclarationTableCell">1 day</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">__utmz</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Collects data on where the user came from, what search engine was used, what link was clicked and what search term was used. Used by Google Analytics.</td>
                                                        <td className="CookieDeclarationTableCell">6 months</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">_ga</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                                                        <td className="CookieDeclarationTableCell">2 years</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">_gat</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Used by Google Analytics to throttle request rate</td>
                                                        <td className="CookieDeclarationTableCell">1 day</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">_gid</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                                                        <td className="CookieDeclarationTableCell">1 day</td>
                                                        <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="CookieDeclarationTableCell">collect</td>
                                                        <td className="CookieDeclarationTableCell"><a target="_blank" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy" title="Google's privacy policy">Google</a></td>
                                                        <td className="CookieDeclarationTableCell">Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</td>
                                                        <td className="CookieDeclarationTableCell">Session</td>
                                                        <td className="CookieDeclarationTableCell">Pixel Tracker</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className="one_line_col">
                                        <div className="box_primary box_10 definitions expand expanded">
                                            <h2 className="expand-click w_icon_24 icon_ribbon">
                                                Definitions and legal references
                                            </h2>
                                            <div className="expand-content">
                                                <h4>Personal Data (or Data)</h4>
                                                <p>Any information regarding a natural person, a legal person, an institution or an association, which is, or can be, identified, even indirectly, by reference to any other information, including a personal identification number.</p>
                                                <h4>Usage Data</h4>
                                                <p>Information collected automatically through this website (or third-party services employed in this website), which can include: the IP addresses or domain names of the computers utilized by the Users who use this website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the website) and the details about the path followed within the website with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>
                                                <h4>User</h4>
                                                <p>The individual using this website, which must coincide with or be authorized by the Data Subject, to whom the Personal Data refers.</p>
                                                <h4>Data Subject</h4>
                                                <p>The legal or natural person to whom the Personal Data refers.</p>
                                                <h4>Data Processor (or Data Supervisor)</h4>
                                                <p>The natural person, legal person, public administration or any other body, association or organization authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.</p>
                                                <h4>Data Controller (or Owner)</h4>
                                                <p>The natural person, legal person, public administration or any other body, association or organization with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of processing of Personal Data and the means used, including the security measures concerning the operation and use of this website. The Data Controller, unless otherwise specified, is the Owner of this website.</p>
                                                <h4>This website</h4>
                                                <p>The means by which the Personal Data of the User is collected and processed.</p>
                                                <h4>Cookies</h4>
                                                <p>Small sets of data stored in the User's device.</p>
                                                <hr />
                                                <h4>Legal information</h4>
                                                <p>Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC, on the subject of Cookies.</p>
                                                <p>This privacy policy relates solely to this website.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="iub_footer">
                                        <p>
                                            Latest update: May 03, 2018
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Container>
                </Wrapper>
                <Footer page="not" />
            </main>
        </Layout>
    </>
    )
}

export default PrivacyPolicyPage
